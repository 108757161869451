.Logo {
  width: 9vw;
  height: 9vw;
}
.CardRow {
  display: flex;
  flex-direction: row;
  width: 30vw;
  height: 15vw;
  justify-content: space-between;
}
.CardRow :hover {
  background-color: rgb(89, 192, 233);
  border-radius: 1vw;
  color: white;
}
.NewHospital {
  width: 13vw;
  height: 13vw;
  border-radius: 1vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}
.Card {
  display: flex;
  justify-content: center;
  margin-top: 2vw;
}
.HospitalLogo {
  width: 9vw;
  height: 9vw;
  margin-top: 0.5vw;
}
.CredText {
  font-size: 1vw;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.LogoCon {
  display: flex;
  justify-content: center;
}
.CardImg {
  display: flex;
  justify-content: center;
}
.LogoutConHome {
  margin: 1vw;
  display: flex;
  justify-content: flex-end;
}

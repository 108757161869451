.BranchMainCon {
  width: 98vw;
  height: 89vh;
  margin-left: 1vw;
  border-radius: 0.8vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.CardView {
  display: flex;
  justify-content: center;
  margin-top: 1vw;
}
.AddBranchCrad {
  width: 13vw;
  height: 13vw;
  border-radius: 1vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.BranchImg {
  display: flex;
  justify-content: center;
}
.BranchName {
  font-size: 1vw;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.AddLogo {
  width: 5vw;
  height: 6vw;
}
.emed_AddBranchLogoContainer {
  display: flex;
  justify-content: center;
}
.emed_branchForm {
  width: 30vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.emed_branchForm1 {
  width: 30vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.emed_branchFormTextrow1 {
  width: 30vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 14.5vw;
}
.emed_Addbench_helperText {
  color: rgb(167, 8, 8);
  font-size: 0.8vw;
}
.emed_addBranch_Country {
  width: 14.5vw;
  height: 2.7vw;
  border-color: #bbb9b9;
  color: #888888;
  border-radius: 0.3vw;
  margin-left: -0.5vw;
}

.FormContainer {
  width: 98vw;
  height: 88vh;
  margin-left: 1vw;
  border-radius: 0.8vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  justify-content: center;
}
.Formlogo {
  width: 8vw;
  height: 8vw;
}
.TextBoxContainer {
  display: flex;
  flex-direction: row;
  margin-left: 1vw;
}
.FormTextBoxCon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 95vw;
  height: 63vh;
  margin-left: 1.5vw;
  margin-top: -1vw;
}
.emed_FormTextBox {
  display: flex;
  justify-content: center;
}
.FormTextrow1 {
  width: 30vw;
  height: 63vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.TextLabel {
  width: 12vw;
  font-weight: 600;
}
.TextInputbox1 {
  width: 18vw;
}
.TextInputbox {
  width: 18vw;
}
.FormTextrow2 {
  width: 30vw;
  height: 54vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.HelperText {
  font-size: 0.7vw;
  color: blue;
  margin-top: -0vw;
}
.LogoContainer {
  display: flex;
  justify-content: flex-start;
}
.HeadView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.BackCon {
  height: 2.5vw;
  width: 2.5vw;
  margin-top: 1vw;
  margin-left: 1vw;
  border-radius: 0.3vw;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.LogoutCon {
  margin: 1vw;
  float: inline-end;
}
.emed_TopHeadNewClient {
  display: flex;
  flex-direction: row;
}
.emed_NewClientHeadTextCon {
  display: flex;
  align-items: center;
  width: 85vw;
  justify-content: center;
}
.emed_NewClientHeadText {
  font-size: 1.5vw;
  font-weight: bold;
}
.emed_Org_Country {
  width: 14.5vw;
  height: 2.7vw;
  border-color: #bbb9b9;
  color: #888888;
  border-radius: 0.3vw;
  margin-left: -0.5vw;
}
.emed_AddLogo_Org {
  width: 14.3vw;
  height: 2.5vw;
  border: solid 1px #c0baba;
  border-radius: 0.3vw;
  padding-top: 0.2vw;
  padding-left: 0.2vw;
  padding-bottom: -1.5vw;
}
.emed_Org_LogoView {
  width: 8vw;
  height: 8vw;
  margin-left: 4vw;
}

.HeadViewPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.BackBtnIcon {
  height: 2.5vw;
  width: 2.5vw;
  margin-top: 1vw;
  margin-left: 1vw;
  border-radius: 0.3vw;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.LogoutBtn {
  margin: 1vw;
  float: inline-end;
}
.PanelLogo {
  width: 5vw;
  height: 5vw;
}
.AdminPanelText {
  font-size: 1.5vw;
  font-weight: bold;
}
.HeadImgs {
  display: flex;
  flex-direction: row;
}
.OrgDetailsCon {
  width: 98vw;
  height: 41vw;
  margin-left: 1vw;
}
.OrgDetailsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -1vw;
}
.OrgnameTextCon {
  width: 25vw;
  display: flex;
  flex-direction: row;
}
.OrgnameText {
  font-size: 1vw;
  color: #888888;
  font-weight: 500;
  width: 11vw;
}
.OrgnameText1 {
  font-size: 1vw;
  font-weight: bold;
}
.OrgaddressText {
  font-size: 1vw;
  color: #888888;
  font-weight: 500;
  width: 8vw;
}
.OrgaddressText1 {
  font-size: 0.9vw;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 12vw;
}
.OrgbrnachText {
  font-size: 0.8vw;
  color: #888888;
  font-weight: 500;
  width: 4vw;
}
.OrgbranchText1 {
  font-size: 0.8vw;
  font-weight: bold;
  width: 10vw;
}
.OrgpinText {
  font-size: 0.8vw;
  color: #888888;
  font-weight: 500;
  width: 5vw;
}
.OrgpinText1 {
  font-size: 0.8vw;
  font-weight: bold;
  width: 10vw;
}
.OrgDetilText {
  font-size: 0.8vw;
  margin-top: -0.4vw;
  font-weight: 500;
}
.OrgaddressTextCon {
  width: 14vw;
  display: flex;
  margin-top: -1.3vw;
}
.OrgaddressCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.DetailsImg {
  margin-top: 1vw;
}
.BtnGroup {
  display: flex;
  flex-direction: row;
  margin-right: "3vw";
}
.TabBtn {
  width: 8vw;
  font-size: 0.7vw;
}
.emed_FilterCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.emed_FilterBoxs {
  display: flex;
  flex-direction: row;
}
.emed_DateCon {
  margin-left: 1vw;
  width: 16vw;
}
.emed_PanelTableHead {
  background-color: #c2c1c1;
  width: 98vw;
  height: 4vh;
  margin-top: 1vw;
  border-radius: 0.2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.emed_PanelHeadText1 {
  width: 8vw;
  margin-left: 0.5vw;
}
.emed_PanelHeadText2 {
  width: 12vw;
}
.emed_PanelHeadText5 {
  width: 15vw;
}
.emed_PanelTableData {
  width: 98vw;
  height: 6vh;
  display: flex;
  flex-direction: row;
  margin-top: 0.5vw;
}
.emed_TableScrollCon {
  width: 98vw;
  height: 45vh;
}
.rdrCalendarWrapper {
  position: absolute;
  z-index: 10;
}
.eMed_GoHomeBtn{
  display: flex;
  justify-content: flex-end;
}
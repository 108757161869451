.emed_DepartmentMainCon {
  width: 98vw;
  height: 89vh;
  margin-left: 1vw;
  border-radius: 0.8vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.emed_checkboxCon {
  width: 20vw;
}
.emed_Checkbox1 {
  display: flex;
  justify-content: center;
}
.emed_Department_BtnGroup {
  display: flex;
  justify-content: center;
  margin-top: 1vw;
}
.emed_Department_helperText {
  color: red;
  font-size: 0.8vw;
}

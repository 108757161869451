.eMed_AccessDetails_Con {
  width: 98vw;
  height: 89vh;
  margin-left: 1vw;
  border-radius: 0.8vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  justify-content: center;
}
.eMed_AccessDetails_CardImg {
  width: 5vw;
  height: 5vw;
}
.eMed_AccessDetails_CardCon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1vw;
}
.eMed_Configure_FO_Time {
  display: flex;
  flex-direction: row;
}
.eMed_Cofig_Fo_Btn {
  display: flex;
  justify-content: center;
}

.LoginPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.LoginPageContainer {
  background-color: white;
  width: 20vw;
  height: 40vh;
  border: 1px solid white;
  border-radius: 1vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 2vw;
}
.loginTextBox {
  margin-top: 1vw;
  display: flex;
  justify-content: center;
}
.loginBtn {
  margin-top: 2vw;
  display: flex;
  justify-content: center;
}
.AddnewUser {
  font-size: 0.9vw;
  margin-top: 2vw;
  display: flex;
  justify-content: center;
}
/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    width: 15vw;
    height: 3vw;
} */
* {
  font-size: 1vw;
}
.logo {
  height: 10vw;
  width: 10vw;
}
.eMed_signin_visibleIcon {
  cursor: pointer;
}

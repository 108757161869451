.eMed_Select_Org_Con {
  width: 98vw;
  height: 89vh;
  margin-left: 1vw;
  border-radius: 0.8vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  justify-content: center;
}
.emed_TopHeadExisting {
  display: flex;
  flex-direction: row;
}
.eMed_Existing_LogoContainer {
  display: flex;
  justify-content: flex-start;
}
.eMed_Existing_Formlogo {
  width: 8vw;
  height: 8vw;
}
.emed_Existing_HeadTextCon {
  display: flex;
  align-items: center;
  width: 85vw;
  justify-content: center;
}
.emed_Existing_HeadText {
  font-size: 1.5vw;
  font-weight: bold;
}
.eMed_Existing_Org_SearchCon {
  margin: 1vw;
}
.eMed_Exising_OrgName {
  display: flex;
  justify-content: center;
}
.eMed_Existing_Card_Scroll {
  width: 98vw;
  height: 60vh;
}
.eMed_Existing_OrgCrad {
  width: 13vw;
  height: 13vw;
  border-radius: 1vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1vw;
}
.eMed_Existing_OrgBranchName {
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.eMed_Existing_Org_CradCon {
  width: 98vw;
  display: flex;
  flex-wrap: wrap;
}
.eMed_Existing_OrgNameText {
  font-weight: bold;
}

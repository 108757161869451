.emed_DoctorProfile_mainCon {
  width: 98vw;
  height: 89vh;
  margin-left: 1vw;
  border-radius: 0.8vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.emed_DoctorDetails_Con {
  width: 98vw;
  height: 64vh;
  display: flex;
  flex-direction: row;
}
.emed_DocDetail_oneCon {
  width: 32vw;
  height: 60vh;
  margin: 1vw;
  margin-left: 3vw;
}
.emed_DocDetail_twoCon {
  width: 25vw;
  height: 60vh;
  margin: 1vw;
  margin-left: 3vw;
  display: flex;
  justify-content: center;
}
.emed_DocDetail_threeCon {
  width: 25vw;
  height: 60vh;
  margin: 1vw;
  margin-left: 3vw;
  display: flex;
  justify-content: center;
}
.emed_firstTextFiled {
  margin-top: 1vw;
}
.emed_firstTextFiled_row {
  display: flex;
  flex-direction: row;
  margin-top: 1vw;
}
.emed_firstRowTextbox {
  margin-left: 1vw;
}
.emed_Doctor_BtnCon {
  display: flex;
  justify-content: center;
}
.emed_Doctor_Country {
  width: 15vw;
  height: 2.7vw;
  border-color: #bbb9b9;
  color: #888888;
  border-radius: 0.3vw;
}
